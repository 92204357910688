<template>
  <NuxtLinkLocale to="/" :external="isExternal" :class="['header-logo', rootClasses]">
    <component :is="logo" class="header-logo__icon" />
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { IHeaderLogoProps } from './HeaderLogo.types';

const {
  $projectConfig: { headerLogo },
} = useNuxtApp();

const props = defineProps<IHeaderLogoProps>();
const { customLogo, hideBackground } = toRefs(props);

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const rootClasses = computed(() => ({
  'header-logo--transparent': hideBackground.value,
}));

const baseLogo = computed(() => {
  if (customLogo.value) return customLogo.value;
  return headerLogo;
});

const logo = computed(() => `${baseLogo.value}${changeXL('L', changeMD('M', 'S'))}`);
</script>

<style scoped lang="scss" src="./HeaderLogo.scss" />
