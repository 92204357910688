<template>
  <div class="reward-popup">
    <div v-if="variant.type === ERewardPopupTypes.BATTLEPASS" class="reward-popup__level">{{ variant.level }}</div>
    <div class="reward-popup__close" @click="$emit('claimAll')">
      <SvgoCloseIcon />
    </div>
    <section class="reward-popup__body">
      <div class="reward-popup__image">
        <NuxtImg
          v-if="rewardImage"
          provider="localProvider"
          :src="rewardImage"
          :width="rewardImageSizes"
          :height="rewardImageSizes"
        />
        <div v-if="caseMultiplier" class="reward-popup__image-multiplier">x{{ caseMultiplier }}</div>
        <div v-if="countsData.count > 1" class="reward-popup__image-stepper">
          <div
            :class="['reward-popup__image-stepper--arrow', stepperArrowClasses(countsData.current === 1)]"
            @click="$emit('previous')"
          >
            <SvgoArrowLeftIcon />
          </div>
          <span class="reward-popup__image-stepper--info">{{ stepperText }}</span>
          <div
            :class="[
              'reward-popup__image-stepper--arrow',
              stepperArrowClasses(countsData.current === countsData.count),
            ]"
            @click="$emit('next')"
          >
            <SvgoArrowRightIcon />
          </div>
        </div>
      </div>
      <div class="reward-popup__content">
        <div class="reward-popup__content-text">
          <h2>{{ infoText }}</h2>
          <NuxtLinkLocale
            v-if="caseInfo"
            class="reward-popup__content-text--link"
            :to="`${useLinks().OPEN_CASE}${caseInfo.link}`"
          >
            {{ caseInfo.name }}
          </NuxtLinkLocale>
          <span v-if="moneyInfo" class="reward-popup__content-text--money">{{ moneyInfo }}</span>
          <MainPageCoinSlot v-if="coinsInfo" :amount="coinsInfo" :icon="eventData.icon" :color="eventData.coinColor" />
        </div>
        <SharedKitButton
          v-if="buttonText"
          :color-preset="ColorPresets.DOTA.PRIMARY"
          :size-preset="changeXL(SizePresets.M, SizePresets.XS)"
          :type-preset="TypePresets.RECTANGLE"
          :text="buttonText"
          :is-loading="isLoading"
          @click="handleClick"
        />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import type { IRewardPopupEmits, IRewardPopupProps } from './RewardPopup.types';
import { ERewardPopupRewardTypes, ERewardPopupTypes } from '~/features/mainPage/Types/rewardPopup/client.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import eventCoinImage from 'assets/images/mainPage/event-coin.png';
import eventRubleImage from 'assets/images/mainPage/event-ruble.png';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';

const { t } = useI18n();

const props = defineProps<IRewardPopupProps>();
const emits = defineEmits<IRewardPopupEmits>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const stepperArrowClasses = computed(() => (isDisabled: boolean) => ({
  'reward-popup__image-stepper--disabled': isDisabled,
}));

const rewardImage = computed(() => {
  if (props.variant.type === ERewardPopupTypes.BATTLEPASS) {
    if (props.variant.reward.type === ERewardPopupRewardTypes.CASE) {
      return props.variant.reward.image;
    }

    if (props.variant.reward.type === ERewardPopupRewardTypes.COINS) {
      return eventCoinImage;
    }

    return eventRubleImage;
  }

  if (props.variant.type === ERewardPopupTypes.DAILY) {
    return props.variant.image;
  }

  return '';
});

const rewardImageSizes = computed(() => changeXL(128, 104));

const caseMultiplier = computed(() => {
  if (props.variant.type !== ERewardPopupTypes.BATTLEPASS) return null;
  if (props.variant.reward.type !== ERewardPopupRewardTypes.CASE) return null;
  if (props.variant.reward.amount < 2) return null;

  return props.variant.reward.amount;
});

const stepperText = computed(() => {
  return `${props.countsData.current}/${props.countsData.count}`;
});

const infoText = computed(() => {
  if (props.variant.type === ERewardPopupTypes.BATTLEPASS) {
    return t('mainPage.rewardPopup.battlepassPrize');
  }

  if (props.variant.type === ERewardPopupTypes.DAILY && props.variant.level) {
    return t('mainPage.rewardPopup.freeCasePrize', { level: props.variant.level });
  }

  return '';
});

const caseInfo = computed(() => {
  if (props.variant.type !== ERewardPopupTypes.BATTLEPASS) return null;
  if (props.variant.reward.type !== ERewardPopupRewardTypes.CASE) return null;
  return props.variant.reward;
});

const moneyInfo = computed(() => {
  if (props.variant.type !== ERewardPopupTypes.BATTLEPASS) return null;
  if (props.variant.reward.type !== ERewardPopupRewardTypes.MONEY) return null;
  return props.variant.reward.amount ? useFormatCurrency(props.variant.reward.amount) : null;
});

const coinsInfo = computed(() => {
  if (props.variant.type !== ERewardPopupTypes.BATTLEPASS) return null;
  if (props.variant.reward.type !== ERewardPopupRewardTypes.COINS) return null;
  return props.variant.reward.amount ? `${props.variant.reward.amount}` : null;
});

const buttonText = computed(() => {
  if (props.variant.type === ERewardPopupTypes.BATTLEPASS) {
    return t('mainPage.claimPrize');
  }

  if (props.variant.type === ERewardPopupTypes.DAILY) {
    return t('mainPage.openFree');
  }

  return '';
});

const styleVariables = computed(() => {
  const shadows = ['0 0 80px 0', '0 0 22px 4px', '0 0 13px 0'];
  const popupShadow = props.eventData.shadow.map((shadow, index) => `${shadows[index]} ${shadow}`).join(',');

  return {
    popupBackground: GlobalUtils.CSS.getBackgroundColor(props.eventData.background),
    popupShadow,
  };
});

const handleClick = () => {
  if (props.variant.type === ERewardPopupTypes.BATTLEPASS) {
    MainPageEvents.rewardClaimed({ 'Event Level': props.variant.level, 'Reward Place': 'info_popup' });
  }
  emits('claim');
};
</script>

<style scoped lang="scss">
.reward-popup {
  --popup-background: v-bind('styleVariables.popupBackground');
  --popup-shadow: v-bind('styleVariables.popupShadow');
}
</style>

<style scoped lang="scss" src="./RewardPopup.scss" />
