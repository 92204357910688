<template>
  <SkeletonComponentLoader>
    <section v-if="!headerLoaded || !userLoaded" class="header-controller-skeleton">
      <HeaderLogo class="header-controller-skeleton__logo" />
      <div class="header-controller-skeleton__menu">
        <SkeletonBaseForeground :width="80" :height="32" :border-radius="8" />
        <SkeletonBaseForeground v-for="i in 6" :key="i" :width="32" :height="32" :border-radius="8" />
        <SkeletonBaseForeground :width="240" :height="32" :border-radius="80" />
      </div>
      <SkeletonBaseForeground
        class="header-controller-skeleton__mobile"
        :width="profileSize.width"
        :height="profileSize.height"
        :border-radius="6"
      />
    </section>
    <HeaderController v-else :hide-reward-popup="hideRewardPopup" />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import type { IHeaderProps } from './HeaderController.types';
import { useHeaderStore } from '~/layers/dota/store/header/header.store';
import { useUserStore } from '~/store/user/user.store';

defineProps<IHeaderProps>();

const headerStore = useHeaderStore();
const { isLoaded: headerLoaded } = storeToRefs(headerStore);

const userStore = useUserStore();
const { isLoaded: userLoaded } = storeToRefs(userStore);

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const profileSize = computed(() => ({
  height: changeXL(32, 28),
  width: changeXL(200, changeMD(220, 208)),
}));
</script>

<style scoped lang="scss" src="./HeaderController.skeleton.scss" />
