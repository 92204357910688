<template>
  <div class="drop-wrapper" @click="selectItem">
    <SharedKitDropItem
      :currency="inventory.currency"
      :name="inventory.name"
      :image="inventory.img"
      :color="GlobalUtils.Colors.getColorsRarity(inventory.rare || 'ARCANA')"
      :price="inventory.price"
      class="drop-item"
      :class="classes"
      no-overlay
    >
    </SharedKitDropItem>
    <SvgoCansellSelected v-if="inventory.isSelected" class="drop-icon" />
  </div>
</template>

<script setup lang="ts">
import type { IEmitsQsDropItem, IPropsQsDropItem } from './QsDropItem.types';

const props = defineProps<IPropsQsDropItem>();
const emits = defineEmits<IEmitsQsDropItem>();

const classes = computed(() => ({
  'drop-item__selected': props.inventory.isSelected,
}));

const selectItem = () => {
  emits('selected-toggle', inventory.value.id);
  emits('check-item-type', inventory.value.id);
};

const { inventory } = toRefs(props);
</script>

<style src="./QsDropItem.style.scss" lang="scss" scoped></style>
