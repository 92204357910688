import type { ComposerTranslation } from '@nuxtjs/i18n/dist/runtime/composables';
import { EHeaderMenuItems } from '~/config/apps/index.types';
import { ProfileEvents } from '~/repository/amplitude/events/profile';

export const MENU_ITEMS_CONFIG = (t: ComposerTranslation) => [
  {
    amplitude: ProfileEvents.secretShopClicked,
    icon: 'SvgoHeaderMenuSecret',
    key: EHeaderMenuItems.SECRETSHOP,
    link: useLinks().SECRETSHOP,
    tooltip: t('secretShop'),
  },
  {
    amplitude: ProfileEvents.bonusClicked,
    icon: 'SvgoHeaderMenuBonuses',
    key: EHeaderMenuItems.BONUS_PAGE,
    link: useLinks().BONUS_PAGE,
    tooltip: t('header.menuItems.bonuses'),
  },
  {
    amplitude: ProfileEvents.contractsClicked,
    icon: 'SvgoHeaderMenuContracts',
    key: EHeaderMenuItems.CONTRACT,
    link: useLinks().CONTRACT,
    tooltip: t('header.menuItems.contract'),
  },
  {
    amplitude: ProfileEvents.upgradeClicked,
    icon: 'SvgoHeaderMenuUpgrades',
    key: EHeaderMenuItems.UPGRADES,
    link: useLinks().UPGRADES,
    tooltip: t('header.menuItems.upgrade'),
  },
  {
    amplitude: ProfileEvents.freeCasesClicked,
    icon: 'SvgoHeaderMenuFree',
    key: EHeaderMenuItems.FREE_CASES,
    link: useLinks().FREE_CASES,
    tooltip: t('freeCases'),
  },
  {
    icon: 'SvgoHeaderMenuLeaderboard',
    key: EHeaderMenuItems.LEADERBOARD,
    link: ROUTING.MAINPAGE.TOP.path,
    tooltip: t('mainPage.headerTabPresets.top'),
  },
  {
    icon: 'SvgoHeaderMenuBattles',
    key: EHeaderMenuItems.BATTLES,
    link: ROUTING.BATTLES.MAIN.path,
    tooltip: t('battlesTitle'),
  },
  {
    icon: 'SvgoHeaderMenuTechies',
    key: EHeaderMenuItems.TECHIES,
    link: ROUTING.TECHIES.MAINPAGE.path,
    tooltip: t('header.menuItems.techies'),
  },
];
